@import './../Variables.scss';

.nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background: $black;
    display: flex;
    justify-content: center;
    z-index: 99;
    box-shadow: -1px 1px #1a1e25;
}

.nav-appear, .nav-exit {
    transform: translateY(-61px);
}

.nav-enter-done {
    transform: translateY(0);
    transition: transform 1s;
}

.nav__links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav__link-container {
    width: 130px;
    text-align: center;
}

.nav__link-container {
    width: 150px;
}

.nav__link-container_left {
    text-align: right;
}

.nav__link-container_right {
    text-align: left;
}

.nav__link {
    text-decoration: none;
    color: $white;
    text-transform: capitalize;
    font-family: 'Josefin Sans';
    letter-spacing: 0.06em;
    font-weight: 200;
    font-size: 1.2em;
    transition: color 0.2s ease-out;
}

.nav__link:hover {
    color: $light-red;
}

.nav__home-icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    top: 4px;
    transition: $hover-transition;
}

.nav__link:hover > .nav__home-icon {
    opacity: 0;
}

.nav__home-icon_highlighted {
    opacity: 0;
    transition: $hover-transition;
}

.nav__link:hover > .nav__home-icon_highlighted {
    opacity: 1;
}

@media (max-width: 767px) {
    .nav__links {
        width: 100%;
    }

    .nav__link-container {
        text-align: center;
        width: 33%;
    }
}
