@import url('https://fonts.googleapis.com/css?family=Assistant:300|Josefin+Sans:300,100');
@import './Variables.scss';

html {
  background-color: $stu-face !important;
}

#root {
  height: 100vh;
}

body {
  display: block;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Assistant', Arial, Helvetica, sans-serif;
  background-color: $stu-face;
  color: $white;
  font-size: 100%;
}

p {
  font-weight: 300;
  font-size: 1.2em;
  letter-spacing: 0.2px;
  line-height:1.5em;
}

button {
  background:none;
  color:inherit;
  border:none; 
  padding:0;
  font: inherit;
  cursor: pointer;
}


@media (max-width: 767px) {
  body {
    font-size: 85%;
  }
}