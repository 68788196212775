@import './Variables.scss';

.non-home-section {
  height: 0; // for vertical alignment in ie 11
  min-height: 100vh;
  width: 100%;
  z-index: 3;
  position: relative;
}

.non-home-section.page-enter {
  position: absolute;
}

.page-exit {
  opacity: 0;
  transition: $page-transition;
}

.home.page-enter ~ .page-exit {
  position: absolute;
}

.page-enter-done {
  opacity: 1;
  transition: opacity 0.5s;
}

.nav-exit {
  transform: translate(0, -61px);
  transition: transform 0.7s cubic-bezier(0,0.93,0.58,1);
}

.nav-enter {
  transform: translate(0, -61px);
}

.nav-enter-done {
  transform: translate(0, 0);
  transition: transform 0.7s cubic-bezier(0,0.93,0.58,1);
}

.app-container {
  height: 100%;
  background: $vignette-background;
  display: flex;
}

.app-container.app-appear {
  opacity: 0;
  background-color: $stu-face;
}

.app-container.app-enter-done {
  opacity: 1;
  background: $vignette-background;
  transition: all 2s;
}

.transition-container {
  height: 100%;
  position: relative;
}

.fade-enter {
  transform: translateY(30px);
  opacity: 0;
}

.fade-enter-done {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.8s;
}

.ie .page-heading {
  height: 92px;
}

.page-heading {
  width: 85%;
  max-width: 350px;
  margin: 0 auto 10px auto;
}

@media(min-width: 768px) {
  .page-heading {
    width: 350px;
    margin: 0 auto 20px auto;
  }
}