@import url(https://fonts.googleapis.com/css?family=Assistant:300|Josefin+Sans:300,100);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html {
  background-color: #111316 !important; }

#root {
  height: 100vh; }

body {
  display: block;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Assistant', Arial, Helvetica, sans-serif;
  background-color: #111316;
  color: #e6e6e6;
  font-size: 100%; }

p {
  font-weight: 300;
  font-size: 1.2em;
  letter-spacing: 0.2px;
  line-height: 1.5em; }

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer; }

@media (max-width: 767px) {
  body {
    font-size: 85%; } }

.non-home-section {
  height: 0;
  min-height: 100vh;
  width: 100%;
  z-index: 3;
  position: relative; }

.non-home-section.page-enter {
  position: absolute; }

.page-exit {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out; }

.home.page-enter ~ .page-exit {
  position: absolute; }

.page-enter-done {
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }

.nav-exit {
  -webkit-transform: translate(0, -61px);
          transform: translate(0, -61px);
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0, 0.93, 0.58, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0, 0.93, 0.58, 1);
  transition: transform 0.7s cubic-bezier(0, 0.93, 0.58, 1);
  transition: transform 0.7s cubic-bezier(0, 0.93, 0.58, 1), -webkit-transform 0.7s cubic-bezier(0, 0.93, 0.58, 1); }

.nav-enter {
  -webkit-transform: translate(0, -61px);
          transform: translate(0, -61px); }

.nav-enter-done {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0, 0.93, 0.58, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0, 0.93, 0.58, 1);
  transition: transform 0.7s cubic-bezier(0, 0.93, 0.58, 1);
  transition: transform 0.7s cubic-bezier(0, 0.93, 0.58, 1), -webkit-transform 0.7s cubic-bezier(0, 0.93, 0.58, 1); }

.app-container {
  height: 100%;
  background: -webkit-radial-gradient(circle, #212631 10%, #111316 70%);
  background: radial-gradient(circle, #212631 10%, #111316 70%);
  display: -webkit-flex;
  display: flex; }

.app-container.app-appear {
  opacity: 0;
  background-color: #111316; }

.app-container.app-enter-done {
  opacity: 1;
  background: -webkit-radial-gradient(circle, #212631 10%, #111316 70%);
  background: radial-gradient(circle, #212631 10%, #111316 70%);
  -webkit-transition: all 2s;
  transition: all 2s; }

.transition-container {
  height: 100%;
  position: relative; }

.fade-enter {
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  opacity: 0; }

.fade-enter-done {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 0.8s;
  transition: all 0.8s; }

.ie .page-heading {
  height: 92px; }

.page-heading {
  width: 85%;
  max-width: 350px;
  margin: 0 auto 10px auto; }

@media (min-width: 768px) {
  .page-heading {
    width: 350px;
    margin: 0 auto 20px auto; } }

.home {
  min-height: 100vh;
  -webkit-flex: 1 1;
          flex: 1 1;
  position: relative; }

.home__face {
  width: 75%;
  max-width: 400px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1; }

.home__title {
  width: 75%;
  max-width: 800px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2; }

.home.home-enter {
  opacity: 1;
  background-color: #111316; }

.home.home-enter-done {
  background-color: radial-gradient(circle, #212631 10%, #131313 70%);
  -webkit-transition: background-color 1.4s ease-out;
  transition: background-color 1.4s ease-out; }

.home.page-enter {
  opacity: 0; }

.home.page-enter-done {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out; }

.home-nav {
  z-index: 2; }

.home-nav-enter {
  opacity: 0; }

.home-nav-enter-done {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out; }

@media (max-width: 767px) {
  .home__title {
    width: 95%;
    max-width: 600px; }
  .home__face {
    width: 85%;
    max-width: 500px; }
  .home-nav {
    position: absolute;
    bottom: 8%;
    width: 100%; }
  .home {
    overflow: hidden; } }

@media (min-width: 768px) and (max-width: 1115px) {
  .home__title {
    width: 65%; } }

.triangle-link-container {
  z-index: 3;
  position: absolute;
  text-align: center; }

.triangle-link-container.triangle-link-container_contact-email {
  position: relative;
  margin-top: 30px; }
  .triangle-link-container.triangle-link-container_contact-email .triangle-link {
    text-transform: none; }

.triangle-link {
  text-decoration: none;
  text-transform: capitalize;
  color: #e6e6e6;
  font-family: 'Josefin Sans';
  font-weight: 200;
  font-size: 1.8em;
  letter-spacing: 0.06em; }

@media (max-width: 767px) {
  .triangle-link-container {
    position: relative;
    margin: 12px auto;
    width: 100%;
    height: auto; } }

@media (min-width: 768px) {
  .triangle-link-container {
    position: absolute;
    margin: 0;
    width: auto; }
  .triangle-link-container_about {
    left: 0;
    bottom: 0;
    margin: auto auto 4% 20%;
    height: 1.1em; }
  .triangle-link-container_music {
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto auto 4% auto;
    width: 70px;
    height: 1.1em; }
  .triangle-link-container_contact {
    right: 0;
    bottom: 0;
    margin: auto 20% 4% auto;
    height: 1.1em; } }

.about {
  display: block;
  margin-top: 80px; }

.about-text {
  width: 80%;
  max-width: 680px;
  margin: 0 auto; }
  .about-text p {
    margin-bottom: 0.7em; }

@media (min-width: 768px) {
  .about {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 0; }
  .about-text {
    width: 50%; } }

.triangle-canvas {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.triangle-canvas_back {
  z-index: 0; }

.triangle-canvas_front {
  z-index: 2; }

.triangle-canvas-appear {
  opacity: 0; }

.triangle-canvas-enter-done {
  opacity: 1;
  -webkit-transition: opacity 3s;
  transition: opacity 3s; }

.contact {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center; }

.contact__email {
  color: #e6e6e6;
  text-decoration: none;
  margin: 22px;
  font-family: 'josefin sans';
  font-weight: 100;
  font-size: 1.7em; }

.contact__description {
  width: 80%; }

.music-item__icon {
  width: 32px;
  margin: 6px;
  position: relative;
  height: 32px;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s; }

.music-item__icon_show {
  opacity: 1; }

.music-item__icon-image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out; }

.music-item__icon-image_highlighted {
  opacity: 0; }

.music-item__icon:hover .music-item__icon-image {
  opacity: 0; }

.music-item__icon:hover .music-item__icon-image_highlighted {
  opacity: 1; }

.music-item {
  position: relative;
  height: 256px;
  width: 256px;
  text-align: center;
  -webkit-transition: -webkit-flex 0.5s;
  transition: -webkit-flex 0.5s;
  transition: flex 0.5s;
  transition: flex 0.5s, -webkit-flex 0.5s;
  font-family: 'Josefin Sans'; }

.music-item__img-enter {
  -webkit-transform: translateY(80px);
          transform: translateY(80px);
  opacity: 0; }

.music-item__img-enter-done {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 0.8s cubic-bezier(0.01, 0.74, 0.41, 1.35);
  transition: all 0.8s cubic-bezier(0.01, 0.74, 0.41, 1.35); }

.music-item__img-enter + .music-item__overlay:hover {
  opacity: 0; }

.music-item__overlay {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s; }

.music-item__overlay:hover {
  opacity: 0.84; }

.music-item__content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 220px; }

.music-item__title {
  margin: 0.5em;
  font-size: 1.4em;
  line-height: 1.2em; }

.music-item__alias {
  margin: 0.5em; }

.music-item__icon-container {
  margin: 0.7em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.alias-filter {
  background-color: #131313;
  padding: 10px 8px 6px 8px;
  color: #e6e6e6;
  margin: 5px;
  border-style: solid;
  border-color: #e6e6e6;
  border-width: 1px;
  -webkit-transition: 1s cubic-bezier(0, 0.5, 0, 1);
  transition: 1s cubic-bezier(0, 0.5, 0, 1);
  font-size: 1em;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
  -webkit-transition: all 0.7s cubic-bezier(0, 0.93, 0.58, 1);
  transition: all 0.7s cubic-bezier(0, 0.93, 0.58, 1); }

.alias-filter:hover {
  -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px);
  box-shadow: -3px 3px #9a252f; }

.alias-filter:active {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  box-shadow: none;
  -webkit-transition-duration: 0.1s;
          transition-duration: 0.1s; }

.alias-filter__selected {
  -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px);
  box-shadow: -3px 3px #9a252f; }

.alias-filter-enter {
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  opacity: 0;
  -webkit-transition: none;
  transition: none; }

.alias-filter-enter-done {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 0.8s cubic-bezier(0.01, 0.74, 0.41, 1.27);
  transition: all 0.8s cubic-bezier(0.01, 0.74, 0.41, 1.27); }

.music-grid {
  margin: 24px 10% 0 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: visible !important; }

.music__heading-anim {
  width: 400px; }

.music {
  padding: 80px 0;
  position: relative;
  height: auto; }

.alias-filter-list {
  text-align: center;
  margin: 0 10px; }

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background: #131313;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 99;
  box-shadow: -1px 1px #1a1e25; }

.nav-appear, .nav-exit {
  -webkit-transform: translateY(-61px);
          transform: translateY(-61px); }

.nav-enter-done {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s; }

.nav__links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.nav__link-container {
  width: 130px;
  text-align: center; }

.nav__link-container {
  width: 150px; }

.nav__link-container_left {
  text-align: right; }

.nav__link-container_right {
  text-align: left; }

.nav__link {
  text-decoration: none;
  color: #e6e6e6;
  text-transform: capitalize;
  font-family: 'Josefin Sans';
  letter-spacing: 0.06em;
  font-weight: 200;
  font-size: 1.2em;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out; }

.nav__link:hover {
  color: #ff5765; }

.nav__home-icon {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  top: 4px;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out; }

.nav__link:hover > .nav__home-icon {
  opacity: 0; }

.nav__home-icon_highlighted {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out; }

.nav__link:hover > .nav__home-icon_highlighted {
  opacity: 1; }

@media (max-width: 767px) {
  .nav__links {
    width: 100%; }
  .nav__link-container {
    text-align: center;
    width: 33%; } }

.loading-anim {
  width: 180px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.copyright {
  position: absolute;
  bottom: 2px;
  left: 2px;
  font-size: 0.8em;
  color: #5d5c5c; }

