@import '../../Variables.scss';

.triangle-link-container {
    z-index: 3;
    position: absolute;
    text-align: center;
}

.triangle-link-container.triangle-link-container_contact-email {
    position: relative;
    margin-top: 30px;

    .triangle-link {
        text-transform: none;
    }
}

.triangle-link {
    text-decoration: none;
    text-transform: capitalize;
    color: $white;
    font-family: 'Josefin Sans';
    font-weight: 200;
    font-size: 1.8em;
    letter-spacing: 0.06em;
}

@media (max-width: 767px) {
    .triangle-link-container {
        position: relative;
        margin: 12px auto;
        width: 100%;
        height: auto;
    }
}

@media (min-width: 768px) {
    .triangle-link-container {
        position: absolute;
        margin: 0;
        width: auto;
    }

    .triangle-link-container_about {
        left: 0;
        bottom: 0;
        //top: 0;
        margin: auto auto 4% 20%;
        height: 1.1em;
    }
    
    .triangle-link-container_music {
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto auto 4% auto;
        width: 70px;
        height: 1.1em;
    }
    
    .triangle-link-container_contact {
        right: 0;
        bottom: 0;
        //top: 0;
        margin: auto 20% 4% auto;
        height: 1.1em;
    }
}