@import './../Variables.scss';

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.contact__email {
    color: $white;
    text-decoration: none;
    margin: 22px;
    font-family: 'josefin sans';
    font-weight: 100;
    font-size: 1.7em;
}


.contact__description {
    width: 80%;
}


