@import '../Variables.scss';

.home {
    min-height: 100vh;
    flex: 1;
    position: relative;
}

.home__face {
    width: 75%;
    max-width: 400px;
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
    margin: auto;
    z-index:1;
}

.home__title {
    width: 75%;
    max-width: 800px;
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    margin: auto;
    z-index:2;
}

// initial homepage intro sequence
.home.home-enter {
    opacity: 1;
    background-color: $stu-face;
}

.home.home-enter-done {
    background-color: radial-gradient(circle, $background 10%, $black 70%);
    transition: background-color 1.4s ease-out;
}

//normal page load fade in
.home.page-enter {
    opacity: 0;
}

.home.page-enter-done {
    opacity: 1;
    transition: $page-transition;
}

.home-nav {
    z-index: 2;
}

.home-nav-enter {
    opacity: 0;
}

.home-nav-enter-done {
    opacity: 1;
    transition: $page-transition;
}

@media (max-width: 767px) {
    .home__title {
        width: 95%;
        max-width: 600px;
    }

    .home__face {
        //left: 60px;
        width: 85%;
        max-width: 500px;
    }

    .home-nav {
        position: absolute;
        bottom: 8%;
        width: 100%;
    }

    .home {
        overflow: hidden;
    }
}

@media (min-width: 768px)  and (max-width: 1115px) {
    .home__title {
        width: 65%;
    }
}
