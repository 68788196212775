
.about {
    display: block;
    margin-top: 80px;
}

.about-text {
    width: 80%;
    max-width: 680px;
    margin: 0 auto;

    p {
        margin-bottom: 0.7em;
    }
}

@media(min-width: 768px) {
    .about {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 0;
    }
    
    .about-text {
        width: 50%;
    }
}