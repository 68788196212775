@import '../../Variables.scss';

.alias-filter {
    background-color: $black;
    padding: 10px 8px 6px 8px;
    color: $white;
    margin: 5px;
    border-style: solid;
    border-color: $white;
    border-width: 1px;
    transition: 1s cubic-bezier(0,0.5,0,1);
    font-size: 1em;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    transition: all 0.7s cubic-bezier(0,0.93,0.58,1);
}

.alias-filter:hover {
    transform:  translate(2px, -2px);
    box-shadow: -3px 3px $red;
}

.alias-filter:active {
    transform:  translate(0, 0);
    box-shadow: none;
    transition-duration: 0.1s;
}

.alias-filter__selected {
    transform:  translate(2px, -2px);
    box-shadow: -3px 3px $red;
}

.alias-filter-enter {
    transform: translateY(40px);
    opacity: 0;
    transition: none;
}

.alias-filter-enter-done {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.8s cubic-bezier(.01,.74,.41,1.27);
}