@import './../Variables.scss';

.music {
    padding: 80px 0;
    position: relative;
    height: auto;
}

.alias-filter-list {
    text-align: center;
    margin: 0 10px;
}

