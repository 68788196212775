.music-item {
    position: relative;
    height: 256px;
    width: 256px;
    text-align: center;
    transition: flex 0.5s;
    font-family: 'Josefin Sans';
}

.music-item__img-enter {
    transform: translateY(80px);
    opacity: 0;
}

.music-item__img-enter-done {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.8s cubic-bezier(.01,.74,.41,1.35);
}

.music-item__img-enter + .music-item__overlay:hover {
    opacity: 0;
}

.music-item__overlay {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0;
    transition: opacity 0.4s;
}

.music-item__overlay:hover {
    opacity: 0.84;
}

.music-item__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 220px;
}

.music-item__title {
    margin: 0.5em;
    font-size: 1.4em;
    line-height: 1.2em;
}

.music-item__alias {
    margin: 0.5em;
}

.music-item__icon-container {
    margin: 0.7em;
    display: flex;
    align-items: center;
    justify-content: center;
}
