.triangle-canvas {
    height: 100%; 
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.triangle-canvas_back {
    z-index: 0;
}

.triangle-canvas_front {
    z-index: 2;
}

.triangle-canvas-appear {
    opacity: 0;
}

.triangle-canvas-enter-done {
    opacity: 1;
    transition: opacity 3s;
}